import { getValues } from '~/utils/shop/general.js'
import { useProductsStore } from '~/store/productsStore.js'
import { useFilterStore } from '~/store/filterStore.js'

import { SHOP_ROUTES_NAMES } from '~/constants'

// if we have preselected diamond disable shape and stone type filters

const { routeName } = useShopRouteName()

export default defineNuxtRouteMiddleware(async (to, from) => {
  const productStore = useProductsStore()
  const filterStore = useFilterStore()

  if (!process.client) return
  if (from != null) window?.DataLayer?.pageView()

  if (to.query.step !== from.query.step) {
    resetGglTrackCalls()
  }

  // Close mobile menu when refreshing the page
  // not sure that it's needed
  // if (window.innerWidth <= 769 && document.querySelector('#inner-nav').offsetWidth > 0) {
  //   document.body.classList.remove('menu')
  //   document.querySelector('#inner-nav').style.width = '0'
  //   document.querySelector('#inner-nav .contentArea').style.display = 'none'
  // }

  // TODO: confirm with Eric what is this ?
  if (window?.notFirstCall) {
    window._h1 = null
    window._h2 = null
    window._h3 = null
  }
  const isExpress = to.query.isExpressShop || to.query.stockNumber

  if (to.query.category !== from?.query?.category || to.query.category) {
    await productStore.getCategory({ category: to.query.isExpressShop ? 12 : to.query.category })
  }

  // triggered only once you change the filter
  if (from?.query?.category === to.query.category && routeName.value === SHOP_ROUTES_NAMES.CATEGORY) {
    try {
      productStore.getAllProducts({
        category: to.query.category,
        query: {
          ...productStore.category.DefaultFilters,
          ...(!isExpress ? to.query : { isExpressShop: true, page: to.query.currentPage || to.query.page }),
          isExpressShop: to.query.isExpressShop,
          ...getValues(filterStore.getQuery(isExpress ? false : null)),
        },
      })
    } catch (e) {
      console.error(e)
    }
  }
})
